class Elementor_Opal_Animated {
    static instance;

    static getInstance() {
        if (!Elementor_Opal_Animated.instance) {
            Elementor_Opal_Animated.instance = new Elementor_Opal_Animated();
        }
        return Elementor_Opal_Animated.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/image.default', ($scope, $) => {
            if ($scope.hasClass('img-animated')) {
                new Waypoint({
                    element: $scope,
                    offset: '50%',
                    handler: function () {
                        $scope.find('.elementor-image').addClass('img-loaded');
                    }
                });
            }
        });

        elementorFrontend.hooks.addAction('frontend/element_ready/column', ($scope, $) => {
            if ($scope.hasClass('col-animated')) {
                new Waypoint({
                    element: $scope,
                    offset: '50%',
                    handler: function () {
                        $scope.addClass('col-loaded');
                    }
                });
            }
        });

    }
}

Elementor_Opal_Animated.getInstance();