class Elementor_Image_Carousel{
    static instance;

    static getInstance() {
        if (!Elementor_Image_Carousel.instance) {
            Elementor_Image_Carousel.instance = new Elementor_Image_Carousel();
        }
        return Elementor_Image_Carousel.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/image-carousel.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
            $scope.find('.image-carousel .image-item:visible').magnificPopup({
                delegate : 'a',
                type     : 'image',
                tLoading : 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery  : {
                    enabled           : true,
                    navigateByImgClick: true,
                    preload           : [0, 1]
                },
                image    : {
                    tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return item.el.attr('title');
                    }
                }
            });
        } );

    }
}

Elementor_Image_Carousel.getInstance();
